<template>
  <v-app>
    <v-app-bar
      app
      color="var(--color-primary)"
      dark
      dense
    >
      <div class="d-flex align-center">
        <v-img
          alt="Trazhalo Logo"
          class="shrink mr-2"
          contain
          src="./assets/logo.png"
          transition="scale-transition"
          width="40"
        />

        TranzSecure
      </div>

      <v-spacer></v-spacer>




       <div>
          {{$auth.profile.name}}
          <v-btn icon @click="$auth.logout()">
             <v-icon>
                mdi-logout
             </v-icon>
          </v-btn>
       </div>


    </v-app-bar>





    <v-main>
      <router-view/>
    </v-main>



  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),

   mounted(){
      this.$auth.$on('logged-out', ()=> {
         console.log('logged out')
         if (this.$route.path !== '/login'){
            this.$router.push({path: '/login'});
         }


      });

      this.$auth.$on('logged-in', ()=> {
         console.log('logged in')
      });



   }
};
</script>


<style src="./views/common.css"></style>