import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false




import { AuthPlugin, getInstance } from "./plugins/auth.js";
// new Vue({
//   router,
//   store,
//   vuetify,
//   render: h => h(App)
// }).$mount('#app')


async function create_app(){
  Vue.use(AuthPlugin, {});

  let auth = getInstance();
  await auth.check_is_logged_in();




  // note need to manually update code in plugins/auth.js as well
  if (process.env.NODE_ENV === 'production'){
    Vue.prototype.$baseUrlApi =  process.env.VUE_APP_BASE_URL_API;
  }else{
    let origin = window.location.origin.split(':')
    Vue.prototype.$baseUrlApi =   origin.slice(0,2).join(':') + ':'+ process.env.VUE_APP_API_PORT;
  }

  console.log(Vue.prototype.$baseUrlApi);

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}

create_app();