<template>
   <v-card class="pa-3" >
      Login

      <v-form ref="form">
         <v-text-field
             style="margin-top: 20px"
             label="Email"
             outlined
             dense
             v-model="email"
             :rules="[isRequired, checkEmail]"
         />

         <v-text-field
             label="Password"
             outlined
             dense
             v-model="password"
             :type="showPass ? 'text' : 'password'"
             :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
             @click:append="showPass = !showPass"
             :rules="[isRequired]"
         />
      </v-form>


      <div style="display: flex;">
         <v-spacer/>
         <v-btn dark color="var(--color-primary)" @click="login">
            Login
         </v-btn>
      </div>
   </v-card>
</template>

<script>

import data_getters from "@/mixins/data_getters";
import validation from "@/mixins/validation";


export default {
   name: "LoginModal",
   mixins: [data_getters, validation],
   props: {
      emailFill: {type: String, default: null},
      redirectOnLogin: {type: Boolean, default: false},
      isModal: {type: Boolean, default: true}, //
   },


   data() {
      return {
         email: this.emailFill,
         password: null,

         showPass: false,

         error_msg: null,
      }
   },

   methods: {
      async login() {
         this.error_msg = null;
         if (!this.$refs.form.validate()) {
            return;
         }

         try {
            let loggedin = await this.$auth.login(this.email, this.password);

            console.log('i ran', this.$auth.isAuthenticated());

            this.$emit('logged-in', loggedin);
            if ( this.$route.path === '/login') {
               await this.$router.push('/');
            }


         } catch (e) {

            this.error_msg = 'Please Check Credentials'
         }
      }

   }//methods
}
</script>

<style scoped>

</style>