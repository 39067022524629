<template>
   <div>
      <div id="mapContainer" class="basemap"></div>

   </div>

</template>

<script>

//https://dev.to/hmintoh/how-to-mapbox-with-vue-js-2a34

import mapboxgl from "mapbox-gl";

// import axios from 'axios';
import data_getters from "@/mixins/data_getters";
import * as d3 from 'd3';

export default {
   name: "BaseMap",
   mixins: [data_getters],
   data() {
      return {
         accessToken: process.env.VUE_APP_MAPBOX,

         gps: [],
         map: null,
      };
   },


   methods:{

      async get_location_history0(){
         try{
            this.gps = await this.make_request('/private/tranzhalo_gps', {});

            for (let x of this.gps.slice(0,)) {
               console.log(x)
               let color = 'black';
               let scale = 0.4;
               let rotation = 45;
               if (x.num_packets > 1) {
                  color = 'red';
                  scale = 1;
                  // rotation =  -45;
               }

               if (x.device_name != null) {
                  color = 'green';
               }


               new mapboxgl.Marker({color, scale, rotation})
                   .setLngLat([x.lng, x.lat])
                   .setPopup(new mapboxgl.Popup({offset: 25}) // add popups
                       .setHTML('<h3>' + x.device_name + '</h3>' + `
                     <div>UAP: ${x.UAP}</div>
                     <div>LAP: ${x.utrx_LAP}</div>
                     <div>lng,lat: ${x.lng}, ${x.lat}</div>
                     <div>Packets: ${x.num_packets}</div>
                  `))
                   .addTo(this.map);
            }
         }
         catch (e) {
            console.error(e);
         }
      },


      async get_location_history() {


         try {
            this.gps = await this.make_request('/private/getLocationHistory', {});


            let zoom = this.map.getZoom();

            let coordinates = [];
            for (let x of this.gps.slice(0,)) {
               // console.log(x)
               let color = 'black';
               let scale = 0.4;
               let rotation = 45;
               if (x.num_packets > 1) {
                  color = 'red';
                  scale = 1;
                  // rotation =  -45;
               }

               if (x.device_name != null) {
                  color = 'green';
               }


               coordinates.push( x.location.coordinates);

            }//for x


            let geo_data = {
               'type': 'Feature',
               'properties': {name: 'Truck Route'},
               'geometry': {
                  'type': 'LineString',
                  coordinates,
               }
            }


            let map = this.map;

            map.on('load', function () {

               console.log('map loaded')

               map.addSource('route', {
                  'type': 'geojson',
                  'data': geo_data
               });

               map.addLayer({
                  'id': 'route',
                  'type': 'line',
                  'source': 'route',
                  'layout': {
                     'line-join': 'round',
                     'line-cap': 'round'
                  },
                  'paint': {
                     'line-color': '#268002',
                     'line-width': 2
                  }
               });

               map.on('click', 'route', function (e) {

                  new mapboxgl.Popup()
                      .setLngLat(e.lngLat)
                      .setHTML(e.features[0].properties.name)
                      .addTo(map);
               });




               // Change the cursor to a pointer when the mouse is over the route layer.
               map.on('mouseenter', 'route', function () {
                  map.getCanvas().style.cursor = 'pointer';
               });

               // Change it back to a pointer when it leaves.
               map.on('mouseleave', 'route', function () {
                  map.getCanvas().style.cursor = '';
               });


            });





         } catch (e) {
            console.error(e);
         }
      },










      async get_device_history() {


         try {
            // this.gps = await this.make_request('/private/getDevicesHistory', {});


            this.gps = await this.make_request('/private/getRogueDevices', {});

            return this.gps;



            // console.log('gps', this.gps)
            //
            // let coordinates = [];
            // for (let x of this.gps.slice(0,)) {
            //    // console.log(x)
            //
            //
            //
            //
            //    coordinates.push( x.location.coordinates);
            //
            // }//for x of gps
            //
            //
            // return {
            //    'type': 'Feature',
            //    'properties': {name: 'Truck Route'},
            //    'geometry': {
            //       'type': 'LineString',
            //       coordinates,
            //    }
            // }


         } catch (e) {
            console.error(e);
         }
      },

   },//methods






   async mounted() {
      mapboxgl.accessToken = this.accessToken;

      this.map = new mapboxgl.Map({
         container: "mapContainer",
         style: "mapbox://styles/mapbox/streets-v11",
         // style: "mapbox://styles/mapbox/satellite-streets-v11",
         // center: [-88.90957, 38.22085086767213],
         center: [-95,39],
         zoom: 5
         // maxBounds: [
         //    [103.6, 1.1704753],
         //    [104.1, 1.4754753],
         // ],
      });

      // var marker2 = new mapboxgl.Marker({ color: 'black', rotation: 45 })
      //     .setLngLat([-93.84159, 45.37059])
      //     .addTo(map);


      this.map.on('zoomend', (event) => {
         let zoom = this.map.getZoom();
         console.log('zoomend event occurred.', zoom);


         // this.get_location_history(event)
      });


      // await this.get_location_history();
      // await this.get_device_history();




      let map = this.map;

      map.on('load', async  () => {

         console.log('I ran')
         let data = await this.get_device_history();
         console.log({data})


         map.addSource('route', {
            'type': 'geojson',
            'data': data.route
         });



         //***** add line for truck *******
         map.addLayer({
            'id': 'route',
            'type': 'line',
            'source': 'route',
            'layout': {
               'line-join': 'round',
               'line-cap': 'round'
            },
            'paint': {
               'line-color': '#414141',
               'line-width': 2
            }
         });





         //***** add dots for devices packets *******
         let colors = [

             '#007cbf',
            '#33c367',

            '#37b1ad',
            '#7e26a4',
            '#e24d77',
            '#c1bf3c',
            '#ffffff',
            '#ffffff',
         ]



         colors = [

            '#ffe93a',
            '#eeff4d',
            '#fa4848',
            '#ec2727',
            '#ec2727',


         ]


         // data.device_points = data.device_points.slice(0,5)

         let n_devices = data.device_points.length;

         for (let i=0; i<n_devices; i++){

            let d = data.devices[i];

            console.log('device', i, d)

            // map.addSource('device-'+i, {
            //    'type': 'geojson',
            //    'data': d
            // });

            // map.addLayer({
            //    'id': 'device-'+i,
            //    'type': 'line',
            //    'source': 'route',
            //    'layout': {
            //       'line-join': 'round',
            //       'line-cap': 'round'
            //    },
            //    'paint': {
            //       'line-color': '#cb0c0c',
            //       'line-width': 2
            //    }
            // });


            let source_id = 'device-'+i;
            let layer_id = source_id + 'layer'

            map.addSource(source_id, {
               'type': 'geojson',
               'data': data.device_points[i]
            });


            let t = (i+4) / (n_devices*1.1) ;
            let c = d3.interpolateRainbow(t);
            c = d3.color(c).hex(); //convert from rgb string to hex string

            if (i < 5){
               c = colors[i];
            }
            // c = colors[0]
            console.log({c})

            c = colors[i%5];


            map.addLayer({
               'id': layer_id,
               'type': 'circle',
               'source': source_id,
               "paint": {
                  "circle-radius": 5,
                  "circle-color": c,
                  "circle-opacity": 0.4,
                  // "circle-stroke-width": 0,

                  "circle-stroke-width": 1,
                  "circle-stroke-color": c,
                  "circle-stroke-opacity": 1,
               },
            });


            map.on('click', layer_id, function (e) {
               e.originalEvent.stopPropagation();
               console.log(e)
               new mapboxgl.Popup()
                   .setLngLat(e.lngLat)
                   // .setHTML(e.features[0].properties.name)
                   .setHTML('<h3>' + e.features[0].properties.name + '</h3>' + `
                     <div>MAC: ${e.features[0].properties.mac_id}</div>
                     <div>Time: ${new Date(e.features[0].properties.date)}</div>
                     <div>lng/lat: ${e.features[0].properties.lng}, ${e.features[0].properties.lat}</div>

                     `
                   )
                   .addTo(map);
            });

            // Change the cursor to a pointer when the mouse is over the states layer.
            map.on('mouseenter', layer_id, function () {
               map.getCanvas().style.cursor = 'pointer';
            });

            // Change it back to a pointer when it leaves.
            map.on('mouseleave', layer_id, function () {
               map.getCanvas().style.cursor = '';
            });



         }//for


         map.on('click', 'route', function (e) {
            new mapboxgl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(e.features[0].properties.name)
                .addTo(map);
         });

         // Change the cursor to a pointer when the mouse is over the states layer.
         map.on('mouseenter', 'route', function () {
            map.getCanvas().style.cursor = 'pointer';
         });

         // Change it back to a pointer when it leaves.
         map.on('mouseleave', 'route', function () {
            map.getCanvas().style.cursor = '';
         });


      });



   },
};
</script>




<style scoped>
.basemap {
    width: 100%;
    height: calc(100vh - 64px);
}
</style>