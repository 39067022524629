<template>
<div style="display: flex; justify-content: center; align-items: center; height: 100vh; max-height: 80vh">

   <div style="max-width: 350px; width: 100% ">

      <login-modal
          style="width: 100%"
      ></login-modal>
   </div>

</div>
</template>

<script>

import LoginModal from "@/components/app/LoginModal";
export default {
name: "Login",
components: {LoginModal},
   created(){
      if (this.$auth.isAuthenticated()){
         this.$router.push({path: '/'})
      }
   }
}
</script>

<style scoped>

</style>