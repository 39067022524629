import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Map from "@/views/map/Map";
import visualize_data from "@/views/visualize_data";

import routes from './routes';

import {getInstance} from "@/plugins/auth";

// add guard
async function login_guard(to, from, next){
  const auth = getInstance();

  let isAuthenticated = await auth.isAuthenticated();

  console.log('route guard')

  // return next();

  // If the user is authenticated,
  if (isAuthenticated || to.fullPath === '/login') {
    next();
  }
  else{
    // Otherwise, log in
    auth.cache_url(to.fullPath);
    next({ path: '/login' });
    // router.push('login');
  }

}


/**
 * guards against non-admin uses navigating to admin only routes
 * @param to
 * @param from
 * @param next
 * @returns {Promise<*>}
 */
async function admin_guard(to, from, next){
  const auth = getInstance();
  let isAuthenticated = await auth.isAuthenticated();
  console.log('admin guard')
  if (isAuthenticated ) {
    if ( auth.profile.acct_type === 'SDS-ADMIN'){
      next();
    }
    else{
      next(Error('Not Authorized'));
    }

  }

  else if (to.fullPath === '/login') {
    next();
  }
  else{
    auth.cache_url(to.fullPath);
    next({ name: 'Login' })
  }

}


Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/',
//     name: 'Map',
//     component: Map
//   },
//
//   {
//     path: '/charts',
//     name: 'visualize',
//     component: visualize_data
//   },
//
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
// ]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {

  console.log('debug route')
  // authGuard(to, from, next);
  login_guard(to, from, next);

});

export default router
