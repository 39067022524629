<template>
   <div>
      <div style="position: absolute; z-index: 100">
         <v-btn @click="update_assets()">
            update
         </v-btn>
      </div>
      <div id="mapContainer" class="basemap"></div>

   </div>

</template>

<script>

//https://dev.to/hmintoh/how-to-mapbox-with-vue-js-2a34

import mapboxgl from "mapbox-gl";

// import axios from 'axios';
import data_getters from "@/mixins/data_getters";
import utilities from "@/mixins/utilities";
import * as d3 from 'd3';

import {DateTime} from 'luxon';




export default {
   name: "BaseMap",
   mixins: [data_getters, utilities],
   data() {
      return {
         // accessToken: 'pk.eyJ1IjoiZXNweWRldiIsImEiOiJja3BlamtqY3gwMDI5Mm9xa3hheDF5czEwIn0.w3Nrt9w0NBri7boFmdKqQg',
         accessToken: process.env.VUE_APP_MAPBOX,

         // gps: [],
         map: null,
         assets: [],

         location_data: []// used to hold data on a path
      };
   },



   methods:{

      // async get_location_history0(){
      //    try{
      //       this.gps = await this.make_request('/private/tranzhalo_gps', {});
      //
      //       for (let x of this.gps.slice(0,)) {
      //          console.log(x)
      //          let color = 'black';
      //          let scale = 0.4;
      //          let rotation = 45;
      //          if (x.num_packets > 1) {
      //             color = 'red';
      //             scale = 1;
      //             // rotation =  -45;
      //          }
      //
      //          if (x.device_name != null) {
      //             color = 'green';
      //          }
      //
      //
      //          new mapboxgl.Marker({color, scale, rotation})
      //              .setLngLat([x.lng, x.lat])
      //              .setPopup(new mapboxgl.Popup({offset: 25}) // add popups
      //                  .setHTML('<h3>' + x.device_name + '</h3>' + `
      //                <div>UAP: ${x.UAP}</div>
      //                <div>LAP: ${x.utrx_LAP}</div>
      //                <div>lng,lat: ${x.lng}, ${x.lat}</div>
      //                <div>Packets: ${x.num_packets}</div>
      //             `))
      //              .addTo(this.map);
      //       }
      //    }
      //    catch (e) {
      //       console.error(e);
      //    }
      // },
      //
      //
      // async get_location_history() {
      //
      //
      //    try {
      //       this.gps = await this.make_request('/private/getLocationHistory', {});
      //
      //
      //       let zoom = this.map.getZoom();
      //
      //       let coordinates = [];
      //       for (let x of this.gps.slice(0,)) {
      //          // console.log(x)
      //          let color = 'black';
      //          let scale = 0.4;
      //          let rotation = 45;
      //          if (x.num_packets > 1) {
      //             color = 'red';
      //             scale = 1;
      //             // rotation =  -45;
      //          }
      //
      //          if (x.device_name != null) {
      //             color = 'green';
      //          }
      //
      //
      //          coordinates.push( x.location.coordinates);
      //
      //       }//for x
      //
      //
      //       let geo_data = {
      //          'type': 'Feature',
      //          'properties': {name: 'Truck Route'},
      //          'geometry': {
      //             'type': 'LineString',
      //             coordinates,
      //          }
      //       }
      //
      //
      //       let map = this.map;
      //
      //       map.on('load', function () {
      //
      //          console.log('map loaded')
      //
      //          map.addSource('route', {
      //             'type': 'geojson',
      //             'data': geo_data
      //          });
      //
      //          map.addLayer({
      //             'id': 'route',
      //             'type': 'line',
      //             'source': 'route',
      //             'layout': {
      //                'line-join': 'round',
      //                'line-cap': 'round'
      //             },
      //             'paint': {
      //                'line-color': '#268002',
      //                'line-width': 2
      //             }
      //          });
      //
      //          map.on('click', 'route', function (e) {
      //             new mapboxgl.Popup()
      //                 .setLngLat(e.lngLat)
      //                 .setHTML(e.features[0].properties.name)
      //                 .addTo(map);
      //          });
      //
      //          // Change the cursor to a pointer when the mouse is over the states layer.
      //          map.on('mouseenter', 'route', function () {
      //             map.getCanvas().style.cursor = 'pointer';
      //          });
      //
      //          // Change it back to a pointer when it leaves.
      //          map.on('mouseleave', 'route', function () {
      //             map.getCanvas().style.cursor = '';
      //          });
      //
      //
      //       });
      //
      //
      //
      //
      //
      //    } catch (e) {
      //       console.error(e);
      //    }
      // },



      get_location_ix(lng, lat){

         for (let ix=0; ix<this.location_data.length; ix++){
            let x = this.location_data[ix];
            if (x.location.coordinates[0] === lng && x.location.coordinates[1] === lat){
               return ix
            }
         }

         return -1;
      },



      /**
       * Get's location history for a given asset
       * @param asset : object, element of this.assets (list returned from get_assets() ) ;
       * @return {Promise<void>}
       */
      async get_asset_location_hist({thd_id}={}){

         let locations = await this.make_request('/client/getLocationHistory', {thd_id });



         // let pointsData = [];
         // for (let x of locations.map_line) {
         //
         //    pointsData.push({
         //       type: 'Feature',
         //       geometry: x.last_location,
         //       properties: {
         //          last_date: x.last_date,
         //          asset_name: x.asset_info.name,
         //          description: x.asset_info.description,
         //          asset_type:  x.asset_info.asset_type,
         //       },
         //    })
         // }
         //
         //
         // let assets_geoJ = {
         //    type: 'FeatureCollection',
         //    features:pointsData
         // }



         let coordinates = locations.raw_data.map(x => x.location.coordinates);
         this.location_data = locations.raw_data;
         let map_line = {
            "type": "FeatureCollection",
            "features": [{
               "type": "Feature",
               "geometry": {
                  "type": "LineString",
                  "coordinates": coordinates
               },
            }]
         };

         this.map.getSource('location_update_line').setData(map_line);








         let pointsData = [];
         for (let x of locations.raw_data) {

            pointsData.push({
               type: 'Feature',
               geometry: {
                  type: "Point",
                  coordinates: x.location.coordinates,
               },
               properties: {
                  date: x.date,
               },
            })
         }

         let assets_geoJ = {
            type: 'FeatureCollection',
            features:pointsData
         }

         console.log(assets_geoJ)
         this.map.getSource('location_update_points').setData(assets_geoJ);







      },



      //
      async get_assets0() {


         try {




            let assets = await this.make_request('/client/getAssetsMapData', {client_id: "60c55bb07366b73d982c8e52" });

            for (let x of assets) {
               console.log(x)
               let color = 'black';
               let scale =1;
               let rotation = 0;
               if (x.num_packets > 1) {
                  color = 'red';
                  scale = 1;
                  // rotation =  -45;
               }



               if (x.device_name != null) {
                  color = 'green';
               }

               if (DateTime.fromISO(x.last_date) < DateTime.local().minus({minutes: 60})){
                  color = 'grey';
               }



               new mapboxgl.Marker({color, scale, rotation})
                   .setLngLat(x.last_location.coordinates)
                   .setPopup(new mapboxgl.Popup({offset: 25}) // add popups
                       .setHTML('<h3>' + x.asset_info.name + '</h3>' + `
                     <div>Asset Type: ${x.asset_info.asset_type}</div>
                     <div>Info: ${x.asset_info.description}</div>
                     <div>Last Update: ${this.fmt_datetime(x.last_date)}</div>
                  `))
                   .addTo(this.map);

               this.assets = assets;
               return assets;
            }


         } catch (e) {
            console.error(e);
         }
      },



      async update_assets(){
         let assets = await this.make_request('/client/getAssetsMapData', {client_id: "60c55bb07366b73d982c8e52" });


         let pointsData = [];
         for (let x of assets) {

            let thd_id = x.asset_info.thd_ids[0];
            pointsData.push({
               type: 'Feature',
               geometry: x.last_location,
               properties: {
                  last_date: x.last_date,
                  isActive: DateTime.fromISO(x.last_date) >= DateTime.utc().minus({minutes: 5}) ? 'yes' : 'no',
                  asset_name: x.asset_info.name,
                  description: x.asset_info.description,
                  asset_type:  x.asset_info.asset_type,
                  thd_id: thd_id,
               },
            })
         }

         let assets_geoJ = {
            type: 'FeatureCollection',
            features:pointsData
         }

         this.map.getSource('points-assets').setData(assets_geoJ);


      },















      async add_assets(map) {


         try {

            let assets = await this.make_request('/client/getAssetsMapData', {client_id: "60c55bb07366b73d982c8e52" });

            console.log(assets)

            let pointsData = [];
            for (let x of assets) {

               let thd_id = x.asset_info.thd_ids[0];


               pointsData.push({
                  type: 'Feature',
                  geometry: x.last_location,
                  properties: {
                     last_date: x.last_date,
                     isActive: DateTime.fromISO(x.last_date) >= DateTime.utc().minus({minutes: 5}) ? 'yes' : 'no',
                     asset_name: x.asset_info.name,
                     description: x.asset_info.description,
                     asset_type:  x.asset_info.asset_type,
                     thd_id: thd_id,
                  },
               })
            }


            let assets_geoJ = {
               type: 'FeatureCollection',
               features:pointsData
            }

            let layer_id = 'points-assets-layer';


            map.addSource('points-assets', {
               'type': 'geojson',
               'data': assets_geoJ
            });



            //https://docs.mapbox.com/mapbox-gl-js/example/data-driven-circle-colors/


            let c = 'green';
            map.addLayer({
               'id': layer_id,
               'type': 'circle',
               'source': 'points-assets',
               "paint": {
                  "circle-radius": 10,
                  //"circle-color": c,
                  "circle-opacity": 1,
                  // "circle-stroke-width": 0,

                  'circle-color': [
                     'match',
                     ['get', 'isActive'],
                     'yes',
                     '#10b615',
                     'no',
                     '#b60a0a',
                     /* other */ '#5e5e5e'
                  ],


                  // "circle-stroke-width": 1,
                  // "circle-stroke-color": c,
                  // "circle-stroke-opacity": 1,
               },
            });


            map.on('click', layer_id, (e)=> {
               e.originalEvent.stopPropagation();
               console.log({e})

               const features = map.queryRenderedFeatures(e.point, {
                  layers: [layer_id]
               });
               if (!features.length) {
                  return;
               }
               const props = features[0].properties;

               console.log({props})

               // this.get_asset_location_hist({"thd_id": "60c55bd37366b73d982c8e57",});

               this.get_asset_location_hist({"thd_id": props.thd_id,});


               new mapboxgl.Popup()
                   .setLngLat(e.lngLat)
                   .setHTML(`<h3>  ${props.asset_name} </h3>
                      <div>Asset Type: ${props.asset_type}</div>
                     <div>Info: ${props.description}</div>

                     <div>Last Update: ${this.fmt_datetime(props.last_date)}</div>`
                   )
                   .addTo(map);
            });






         } catch (e) {
            console.error(e);
         }
      },


   },//methods












   async mounted() {
      mapboxgl.accessToken = this.accessToken;

      let map = new mapboxgl.Map({
         container: "mapContainer",
         style: "mapbox://styles/mapbox/streets-v11",
         // style: "mapbox://styles/mapbox/satellite-streets-v11",
         // center: [-88.90957, 38.22085086767213],
         // center: [-95,39],
         center: [-84.25, 33.917],
         zoom: 9
         // maxBounds: [
         //    [103.6, 1.1704753],
         //    [104.1, 1.4754753],
         // ],
      });

      // var marker2 = new mapboxgl.Marker({ color: 'black', rotation: 45 })
      //     .setLngLat([-93.84159, 45.37059])
      //     .addTo(map);
      this.map = map;



      map.on('load', async () => {


         //add layer for points

         // let assets = await this.make_request('/client/getAssetsMapData', {client_id: "60c55bb07366b73d982c8e52" });




         this.add_assets(map);



         //add a layer to update dynamically with a history path
         map.addSource('location_update_line', { type: 'geojson', data: null });

         map.addLayer({
            "id": "location_update_layer_line",
            "type": "line",
            "source": 'location_update_line',
            "layout": {
               "line-join": "round",
               "line-cap": "round"
            },
            "paint": {
               "line-color": "#888",
               "line-width": 1,

            },


         });


         map.addSource('location_update_points', { type: 'geojson', data: null });
         map.addLayer({
            'id': "location_update_layer_points",
            'type': 'circle',
            'source': 'location_update_points',
            "paint": {
               "circle-radius": 3,
               "circle-color": 'gray',
               "circle-opacity": 1,
               // "circle-stroke-width": 0,


               // "circle-stroke-width": 1,
               // "circle-stroke-color": c,
               // "circle-stroke-opacity": 1,
            },
         });


         const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            className: 'history-popup',
            maxWidth: '700px'
         });


         map.on('mouseenter', 'location_update_layer_points', (e) => {

            console.log({e})
            // Change the cursor style as a UI indicator.
            map.getCanvas().style.cursor = 'pointer';

            let coordinates = e.lngLat;
            let description = coordinates.toString();

            // let ix = this.get_location_ix(e.lngLat.lng, e.lngLat.lat);
            //
            // let date =  this.location_data[ix].date;
            //


            description = '<div class="history-popup">';
            for (let f of e.features){
               description += f.geometry.coordinates[0];
               description += ', '
               description += f.geometry.coordinates[1]
               description += ', '
               // description += DateTime.fromISO(f.properties.date).toFormat('yyyy-mm-dd, H:mm:ss.SSS');
               description += f.properties.date
               description += '<br> '
            }
            description += '</div>';

            // let feature = e.features[0];
            // description += feature.properties.date


            // Populate the popup and set its coordinates
            // based on the feature found.
            popup.setLngLat(coordinates).setHTML(description).addTo(map);
         });

         map.on('mouseleave', 'location_update_layer_points', () => {
            map.getCanvas().style.cursor = '';
            popup.remove();
         });


















         // let c = "#626262"
         // map.addLayer({
         //    'id': "location_update_layer2",
         //    'type': 'circle',
         //    'source': 'location_update',
         //    "paint": {
         //       "circle-radius": 3,
         //       "circle-color": c,
         //       "circle-opacity": 0.8,
         //       // "circle-stroke-width": 0,
         //
         //       // "circle-stroke-width": 1,
         //       // "circle-stroke-color": c,
         //       // "circle-stroke-opacity": 1,
         //    },
         // });


         // await this.get_asset_location_hist({"thd_id": "60c55bd37366b73d982c8e57",})


      }); //onload


      //
      // map.on('click',   (e) => {
      //
      //    console.log('I ran')
      //    this.map.getSource('location_update').setData({
      //       type: 'FeatureCollection',
      //       features: [],
      //    });
      // });



      map.on('zoomend', (event) => {
         let zoom = this.map.getZoom();
         console.log('zoomend event occurred.', zoom);
      });


      // await this.get_location_history();
      // await this.get_device_history();











   },


};
</script>




<style scoped>
.basemap {
    width: 100%;
    height: calc(100vh - 64px);
}


</style>

<style>
.history-popup{
    width: 500px;
}
</style>