import Map from "@/views/map/Map";
import Map_rogue  from "@/views/map/Map_rogue";
import visualize_data from "@/views/visualize_data";

import Login from "@/views/Login";

export default [
   {
      path: '/',
      name: 'Map',
      component: Map
   },

   {
      path: '/rogue',
      name: 'Map_rogue',
      component: Map_rogue
   },


   {
      path: '/charts',
      name: 'visualize',
      component: visualize_data
   },

   {
      path: '/login',
      name: 'Login',
      component: Login
   },

   {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
   }
]