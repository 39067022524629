<template>
<div>
   test
   <vue-e-charts
       v-if="chart_data != null"
       :options="chart_data"
       :autoresize="true"
       style="width:100%; height:100%"

       ref="chart"
   ></vue-e-charts>
</div>
</template>

<script>

import vueECharts from 'vue-echarts';
import data_getters from "@/mixins/data_getters";

export default {
   name: "visualize_data",
   mixins: [data_getters],
   components: {vueECharts},
   data(){
      return {
         chart_data: null
      }
   },

   methods:{

   },

}
</script>

<style scoped>

</style>